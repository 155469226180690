@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.colorChange {
  color: #e7375b;
}

.order {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee204a;
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  margin-left: 38%;
}

.order:active {
  background-color: #fff;
  color: #ee204a;
}

.active {
  background-color: #f00f3d;
  color: #fff;
  border-radius: 8px;
  height: 48px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .page-wrapper {
    left: 0;
    margin-left: 272px;
    position: relative;
    transition: all 0.2s ease-in-out;
  }
}

.page-wrapper-2 {
  left: 0;
  margin-left: 50%;
  position: relative;
}

a {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding-left: 0.6rem;
}

.headerfont {
  font-family: 'DM Sans', sans-serif;
}

.animation {
  background-color: #fbdddd;
  animation: mymove 5s infinite;
  position: relative;
}

.Poppins {
  font-family: 'Poppins', sans-serif;
}

.style {
  border-bottom-color: #25213b;
  border-bottom-width: 2px;
  padding-bottom: 0.68rem;
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 10px;
}

thead,
tfoot {
  background-color: #f4f2ff;
  border-top: 1.2px #d9d5ec solid !important;
  border-bottom: 1.1px #d9d5ec solid !important;
}

.table-container > div {
  padding: 0 !important;
}

.bold {
  font-weight: 700;
}

.color {
  color: #fa9138;
}

.delivered {
  background-color: #b6fae1;
  color: #0da06a;
}

.cancelled {
  background-color: #f8aec4;
  color: #ee204a;
}

.approved {
  background-color: #e6e6f2;
  color: #4a4aff;
}

.declined {
  background-color: #ee204a;
  color: #ee204a;
}

.pink {
  color: #f81645;
}

.SecondDot {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}

.mapContainer {
  width: 100%;
  height: 28.75rem;
  border-radius: 1rem;
}

.mapContainer-2 {
  width: 100%;
  height: 38.625rem;
  border-radius: 3px;
}

.mapContainer-3 {
  width: 100%;
  height: 80vh;
  border-radius: 1rem;
}

.scrollBar::-webkit-scrollbar {
  width: 3px;
}

.scrollBar::-webkit-scrollbar-track {
  background-color: #abbed1;
}

.scrollBar::-webkit-scrollbar-thumb {
  background-color: #ea6f06;
}

.rotate {
  animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.charts {
  color: #fa8a2b;
  border-bottom-color: #fa8a2b;
  border-bottom-width: 2px;
  padding-bottom: 0.1rem;
}

.Loading {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Loading::after {
  content: '';
  position: absolute;
  height: 100%;
  left: 0;
  width: 0;
  animation: load infinite 1.5s;
}

@keyframes load {
  0% {
    width: 0%;
    background-color: #ee204a;
  }
  25% {
    width: 25%;
    background-color: #ee204a;
  }
  50% {
    width: 50%;
    background-color: #ee204a;
  }
  75% {
    width: 75%;
    background-color: #ee204a;
  }
  100% {
    width: 100%;
    background-color: #ee204a;
  }
}

.yellow {
  color: #f98b0d;
}

.grey {
  color: #4e525f;
}

.loginText {
  text-decoration: underline;
  color: #e7375b;
  font-weight: 700;
}

.calendarElement {
  position: absolute;
  right: 50%;
  transform: translateX(0%);
  top: 1.8rem;
  border: 1px solid #ccc;
  z-index: 999;
}

.sidebar-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: initial;
}

/* For Webkit-based browsers (e.g., Chrome, Safari) */
.sidebar-scrollbar::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

.sidebar-scrollbar::-webkit-scrollbar-thumb {
  background-color: initial;
}

.sidebar-scrollbar::-webkit-scrollbar-track {
  background-color: initial;
}
